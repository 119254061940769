import { useMemo } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { ColumnDef, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, SortingState, useReactTable, flexRender, Row, Column, Table as TanstackTable, CellContext } from "@tanstack/react-table";
import { useForm, FormProvider } from "react-hook-form";
import { atom, useAtom } from "jotai";
import { TStream } from "types/api"
import { STREAMS_COLUMN } from "config/column";
import { GrayButton, GreenButton, RedButton } from "components/layouts/button/Base"
import { ModalBase } from "components/layouts/modal/Base"
import { BaseText, ModalContentText } from "components/layouts/text/Base"
import { streamsAtom, streamStatusAtom } from "components/pages/atoms/streams";
import { CheckBoxColumn, InputColumn, NotSelectedEditColumn, SelectColumn, SelectedEditColumn } from "./Column";
import { CheckBoxFilter, InputFilter, SelectFilter } from "./Filter";
import { CreateRow } from "./CreateRow";
import { addButtonAtom, modalAtom } from "../atoms/common";

/**
 * ストリームテーブル　コンポーネント
 */
export const StreamsTable: React.FC = () => {
  // ストリームデータAtom
  const [streams] = useAtom(streamsAtom)
  // ストリームステータスAtom
  const [streamStatus] = useAtom(streamStatusAtom)
  //　カラム定義
  const columns = useMemo<ColumnDef<TStream>[]>(
    () => STREAMS_COLUMN, []
  )
  const [rowSelection, setRowSelection] = useAtom(ReactTableRowSelectionAtom)
  const [sorting, setSorting] = useAtom(ReactTableSorting)

  // テーブルデータ定義
  const table = useReactTable({
    data: streams,
    columns: columns,
    state: {
      rowSelection,
      sorting,
    },
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false,
  })
  // テーブルhead情報
  const head = table.getHeaderGroups()[0].headers
  // テーブルbody情報
  const body = table.getRowModel().rows
  // テーブルページネーション情報
  const { pageSize, pageIndex } = table.getState().pagination
  // ＋Addボタン 新規追加フォームAtom
  const [addButton, setAddButton] = useAtom(addButtonAtom)
  // 削除モーダル　開閉Atom 
  const [modal, setModalBase] = useAtom(modalAtom)
  // 最終ページのpageIndexを取得
  const lastPageIndex = (streams.length % pageSize === 0 && table.getPageCount() !== 1) ? table.getPageCount() : table.getPageCount() - 1
  // Stream add page処理
  const onSetPage = () => {
    if (streams.length === pageSize) {
      table.setPageSize(pageSize + 1)
    }
    table.setPageIndex(lastPageIndex)
  }
  return (
    <>
    <TableContainer sx={{ maxHeight: 700 }}>
      <Table stickyHeader aria-label="sticky-table">
        <TableHead>
          <TableRow>
            {head.map((header, index) => (
              <TableCell key={index} size="small" sx={{ width: header.column.columnDef.meta?.width }}>
                {header.id !== 'edit' ?
                  <div>
                    <div
                      {...{
                       className: header.column.getCanSort()
                         ? 'cursor-pointer select-none'
                         : '',
                       onClick: header.column.getToggleSortingHandler(),
                      }}                     
                    >
                      <Box display="flex" sx={{ cursor: 'pointer' }}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <Box paddingLeft={1}>
                          {{
                            asc: '↑',
                            desc: '↓',
                          }[header.column.getIsSorted() as string] ?? '↑↓'}
                        </Box>
                      </Box>
                    </div>
                    {header.column.getCanFilter() ? (
                        <div>
                          <Filter column={header.column} table={table} />
                        </div>
                    ): null}
                  </div>
                : 
                  <Box textAlign="center">
                    <GreenButton startIcon={<AddIcon />} onClick={() => setAddButton(true)} disabled={addButton}>
                      ADD
                    </GreenButton>
                  </Box>
                }
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {addButton &&  <CreateRow setPage={onSetPage}/>}
          {(streamStatus === 'loading' || streamStatus === 'error') ?
            <></>
          : body.length === 0 ?
            <TableRow>
              <TableCell colSpan={10} align="center">
                <BaseText text="データが見つかりません"/>
              </TableCell>
            </TableRow>
          :
            body.map((row, index) => (
              <Rows key={index} row={row} index={index}/>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      component="div"
      count={table.getFilteredRowModel().rows.length}
      rowsPerPage={pageSize}
      rowsPerPageOptions={[10, 50, { label: 'All', value: streams.length}]}
      page={pageIndex}
      onPageChange={(_, page) => {
        setRowSelection({})
        table.setPageIndex(page)
      }}
      onRowsPerPageChange={e => {
        const size = e.target.value ? Number(e.target.value) : 15
        table.setPageSize(size)
      }}
    />
    {modal &&
      <ModalBase 
        title={String(modal.name)}
        content={<ModalContentText text="このストリームを削除しますか？"/>}
        actions={
        <>
          <GrayButton startIcon={<CancelIcon />} onClick={() => setModalBase(null)}>キャンセル</GrayButton>
          <RedButton startIcon={<CheckIcon />} onClick={modal.onClick}>OK</RedButton>
        </>
        }
      />
    }
    </>
  )
}
// 選択中保持Atom
const ReactTableRowSelectionAtom = atom({})
// ソート情報保持Atom
const ReactTableSorting = atom<SortingState>([])

/**
 * Stream Table　各行のコンポーネント
 */
type TRow = {
  row: Row<TStream>,
  index: number
}
const Rows: React.FC<TRow> = ({row, index}) => {
  const methods = useForm<TStream>({mode: 'onSubmit'})
  return (
    <FormProvider {...methods}>
      <TableRow>
        {row.getVisibleCells().map(cell => {
          return (
            <TableCell key={cell.id} sx={{ backgroundColor: index % 2 === 0 ? '#f5f9ff' : 'inherit', padding: '10px 16px' }}> 
              <StreamColumn children={cell.getContext()} />
            </TableCell>
          )
        })}  
      </TableRow>
    </FormProvider>
  )
}

/**
 * Stream Table　各カラムのコンポーネント
 */
type ColumnProps = {
  children: CellContext<TStream, unknown>
}
// 各カラム
const StreamColumn: React.FC<ColumnProps> = ({children} ) => {
  // props取得
  const props = children
  // カラム meta情報
  const meta = props.column.columnDef.meta

  // 編集可能　行取得
  const rowSelection = props.table.getState().rowSelection
  // 編集可能選択判定
  const isSelect = Object.keys(rowSelection).includes(props.row.id)
  // 値取得
  const value = props.getValue()
  return (
    meta?.type === 'input' ?
      isSelect ?
        <InputColumn props={props} />
      : 
        <BaseText text={String(value)} />
    : meta?.type === 'select' ?
      isSelect ? 
        <SelectColumn props={props} />
      :
        <BaseText text={meta.options[Number(value)]} />
    : meta?.type === 'checkbox' ?
      isSelect ?
        <CheckBoxColumn props={props} />
      :
        <BaseText text={Boolean(value) ? 'ON' : 'OFF'} />
    : 
      isSelect ?
        <SelectedEditColumn props={props} />
      :
        <NotSelectedEditColumn props={props} />
  )
 
}

type FilterProps = {
  column: Column<any, any>,
  table: TanstackTable<any>
}
/**
 * 該当フィルター判定コンポーネント
 */
export const Filter = ({column, table}: FilterProps) => {
  const meta = column.columnDef.meta
  const columnFilterValue = column.getFilterValue()
  const props = {column: column, columnFilterValue: columnFilterValue, table: table}
  return (
    meta?.type === 'input' ?
      <InputFilter {...props} />
    : meta?.type === 'select' ?
      <SelectFilter {...props} />
    : meta?.type === 'checkbox' ? 
      <CheckBoxFilter {...props} />
    : 
      <></>
  )
}
