import { Box, TextField, } from "@mui/material";
import { InputLabelText } from "components/layouts/text/Base";
import { useSetAtom } from "jotai";
import { useMutation } from "@tanstack/react-query";
import { updatePassword } from "services/auth";
import { ModalBase } from "components/layouts/modal/Base";
import { passwordModalAtom, progressModalAtom } from "../atoms/common";
import { useForm } from "react-hook-form";
import { GrayButton, GreenButton } from "components/layouts/button/Base";
import { VALIDATION } from "config/validation";


type PasswordForm = {
  password: string,
  confirm_pass: string,
}
// パスワード変更モーダル
const ChangePasswordModal = () => {
  // パスワード変更モーダル Set Atom
  const setPasswordModal = useSetAtom(passwordModalAtom)
  // 通信モーダル　Set Atom
  const setProgressModal = useSetAtom(progressModalAtom)
  // パスワード更新処理
  const { mutate: changePassword } = useMutation(
    updatePassword,
    {
      onSuccess: () => {
        setProgressModal(false)
        setPasswordModal(false)
      },
      onError: () => {
        setProgressModal(false)
      }
    }
  )
  // react-hook-form定義
  const { register, reset, getValues, handleSubmit, formState: {errors} } = useForm<PasswordForm>()
  // 送信処理
  const onSubmit = (value: PasswordForm) => {
    setProgressModal(true)
    const param = {
      password: value.password
    }
    changePassword(param)
  }
  // モーダル閉じる
  const onCloseModal = () => {
    reset()
    setPasswordModal(false)
  }
  return (
    <ModalBase
      title="パスワード変更"
      content={
        <>
          <Box sx={{ paddingTop: "16px" }}>
            <InputLabelText text="新規パスワード:"/>
            <TextField
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              {...register('password', {
                ...VALIDATION.password
              })}
              type="password"
            />
          </Box>
          <Box sx={{ paddingTop: "16px" }}>
            <InputLabelText text="新規パスワード(確認):"/>
            <TextField
              error={Boolean(errors.confirm_pass)}
              helperText={errors.confirm_pass?.message}
              {...register('confirm_pass', {
                validate: {
                  confirm: v => v === getValues('password') || 'パスワードが一致していません。'
                },
                ...VALIDATION.confirm_pass
              })}
              type="password"
            />
          </Box>
        </>
      }
      actions={
        <>
          <GrayButton onClick={onCloseModal}>キャンセル</GrayButton>
          <GreenButton onClick={handleSubmit((value) => onSubmit(value))}>Save</GreenButton>
        </>
      }
    />
  )
}

export default ChangePasswordModal;