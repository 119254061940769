import { Avatar, Box, Container, Grid, Paper, TextField, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { GreenButton } from "components/layouts/button/Base";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { authLogin } from "services/auth";
import { InputLabelText } from "components/layouts/text/Base";
import { useNavigate } from "react-router-dom";
import { authAtom } from "components/pages/atoms/auth";
import { LoginAlert } from "components/layouts/alert/Base";
import { useSetAtom } from "jotai";
import { VALIDATION } from "config/validation";

type TLoginForm = {
  email: string,
  password: string,
}

/**
 * ログイン画面 
 */
const Login: React.FC = () => {
  // Login Form 
  const {register, handleSubmit, setError, formState} = useForm<TLoginForm>({ mode: "onBlur"})
  const navigate = useNavigate()
  const setAuth = useSetAtom(authAtom)
  // 送信処理
  const onSubmit: SubmitHandler<TLoginForm> = (data) => {
    return authLogin(data)
  }
  const { mutate: login } = useMutation(
    onSubmit,
    {
      onSuccess: (data, value) => {
        setAuth({auth: true, ...value})
        navigate('/manage/streams')
      },
      onError: () => {
        setError('email', { type: 'error', message: '' })
        setError('password', { type: 'error', message: '' })
      },
    }
  )
  return (
    <Container component="main" maxWidth="xs">
      {formState.errors.email?.type === 'error' && <LoginAlert />}
      <Box sx={{ position: "absolute", top: "50%",left: "50%", transform: "translate(-50%, -50%)",  }}>
        <Paper elevation={3} sx={{ p: 4 , m: "auto", height: "500px", width: "330px",alignItems: "center" }}>
          <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Grid container direction="column" alignItems="center">
              <Avatar>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" sx={{ m: "20px" }}>T24 Admin Login</Typography>
            </Grid>
            <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit((values) => login(values))}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabelText text="メールアドレス:"/>
                  <TextField
                    error={Boolean(formState.errors.email)}
                    helperText={formState.errors.email?.message}
                    {...register('email', {...VALIDATION.email})}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabelText text="パスワード:"/>
                  <TextField
                    error={Boolean(formState.errors.password)}
                    helperText={formState.errors.password?.message}
                    {...register('password', {...VALIDATION.password})}
                    type="password"
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <GreenButton sx={{ width: "100%" }} type="submit" disabled={!formState.isValid}>Sing In</GreenButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Container>   
  )
}

export default Login;