import { atom } from "jotai";
import { TCamera } from "types/api";

// Room, camera削除モーダル
type deleteType = { onClick: () => void, title: any, contents: string }
type deleteModalState = null | deleteType
export const deleteModalAtom = atom<deleteModalState>(null)

// カメラセレクトモーダル
type cameraModalState = null | { onClick: () => void } & TCamera
export const cameraModalAtom = atom<cameraModalState>(null)