import { Button, styled } from "@mui/material";
import { ButtonProps } from "@mui/material";

export const GreenButton = styled(Button)<ButtonProps>(() => ({
  color: "#fff",
  backgroundColor: "#00d68f",
  borderColor: "#00d68f",
  "&: disabled": {
    backgroundColor: "#edf1f7",
    borderColor: "#edf1f7",
  },
  "&: hover": {
    backgroundColor: "#03fcaa",
    borderColor: "#03fcaa",
  },
}))

export const ApplyButton = styled(Button)<ButtonProps>(() => ({
  color: "#fff",
  backgroundColor: "#fa0",
  borderColor: "#fa0",
  "&: disabled": {
    backgroundColor: "#edf1f7",
    borderColor: "#edf1f7",
  },
  "&: hover": {
    backgroundColor: "#fac04b",
    borderColor: "#fac04b",
  }
}))

export const GrayButton = styled(Button)<ButtonProps>(() => ({
  color: "#222b45",
  backgroundColor: "#edf1f7",
  borderColor: "#edf1f7",
  "&: disabled": {
    backgroundColor: "#edf1f7",
    borderColor: "#edf1f7",
  },
  "&: hover": {
    backgroundColor: "#f7f8fa",
    borderColor: "#f7f8fa",
  }
}))

export const RedButton = styled(Button)<ButtonProps>(() => ({
  color: "#fff",
  backgroundColor: "#ff3d71",
  borderColor: "#ff3d71",
  "&: disabled": {
    backgroundColor: "#edf1f7",
    borderColor: "#edf1f7",
  },
  "&: hover": {
    backgroundColor: "#fa5c87",
    borderColor: "#fa5c87",
  }
}))