import { Box, Stack } from "@mui/material"
import { TRoom } from "types/api"
import { GrayButton, GreenButton } from "components/layouts/button/Base"
import { RoomCamerasTable } from "components/pages/cameras/parts/Table"
import { RoomNameText } from "components/layouts/text/Base"
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from "react-router-dom"
import { useAtom } from "jotai"
import { roomsAtom } from "./atoms/rooms"

/**
 * 該当SiteのRoom一覧ページ
 */
const Cameras: React.FC = () => {
  const navigate = useNavigate()
  // Room情報Atom
  const [rooms] = useAtom(roomsAtom)

  // path
  const { site_id } = useParams()

  // edit button
  const onEdit = (value: number) => {
    navigate(`/manage/cameras/${site_id}/edit/${value}`)
  }
  // add button
  const onCreate = () => {
    navigate(`/manage/cameras/${site_id}/create`)
  }
  return (
    <>
      <Box>
        <GreenButton startIcon={<AddIcon />} onClick={onCreate} >ADD ROOM</GreenButton>
      </Box>
      {rooms.map((value: TRoom, index: number) => (
        <Box key={index} sx={{ my: 2, px: 2, py: 3, border: "1px solid #edf1f7"}}>
          <Stack direction="row" justifyContent="space-between">
            <RoomNameText text={value.no !== 0 ? `Room${value.no} - ${value.name}` : value.name}/>
            <GrayButton startIcon={<EditIcon />} onClick={() => onEdit(Number(value.no))}>EDIT ROOM</GrayButton>
          </Stack>
          <RoomCamerasTable cameras={value?.cameras} type='normal' />
        </Box>
      ))}
    </>
  )
}

export default Cameras