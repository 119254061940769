import { useMutation, useQuery } from "@tanstack/react-query";
import { ProgressModal } from "components/layouts/modal/Base";
import { activeApplySeverAtom, progressModalAtom, siteNameAtom } from "components/pages/atoms/common";
import ContentHeader from "components/pages/parts/ContentsHeader";
import { useAtom, useSetAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { getSiteCameras, updateSiteCameras } from "services/sites";
import { deleteCameraApiAtom, deleteRoomApiAtom, roomsAtom } from "../atoms/rooms";

/**
 * カメラアングル画面 共通コンポーネント
 */
const Common: React.FC = () => {
  // Room情報Atom
  const [rooms, setRooms] = useAtom(roomsAtom)
  // Room削除Data API送信用 Atom
  const [deleteRoomApi] = useAtom(deleteRoomApiAtom)
  // Cameras削除Data API送信用　Atom
  const [deleteCameraApi] = useAtom(deleteCameraApiAtom)
  // 通信Modal Atom
  const setProgress = useSetAtom(progressModalAtom)
  // Apply Server Button 有効判定 Atom
  const [activeApplySever, setActiveApplyServer] = useAtom(activeApplySeverAtom)
  // サイト名保持　Set Atom
  const setSiteName = useSetAtom(siteNameAtom)
  // path
  const { site_id } = useParams()
  // Apply Server params
  const params = {
    rooms: rooms,
    deleted_cameras: deleteCameraApi,
    deleted_rooms: deleteRoomApi
  }

  // Apply Server Button処理
  const { mutate: updateSiteRooms } = useMutation(
    () => updateSiteCameras(String(site_id), params),
    {
      onSuccess: (data) => {
        setActiveApplyServer(false)
        setRooms(data.data.rooms)
        setProgress(false)
      }
    }
  )
  // サイトに紐ずくRooms Cameras情報を取得
  const { data, refetch, isLoading } = useQuery(['getSiteCameras'],
    () => getSiteCameras(String(site_id)),
    {
      onSuccess: (data) => {
        setRooms(data.data.rooms)
        setSiteName(data.data.name)
      }
    }
  )
  // site_id変化時処理
  const changeSite = useCallback(() => {
    refetch()
    setActiveApplyServer(false)
  }, [refetch, setActiveApplyServer])


  // site_id変わったら再取得
  useEffect(() => {
    changeSite()
  }, [site_id, changeSite])

  // update 処理
  const update = () => {
    setProgress(true)
    updateSiteRooms()
  }
  return (
    <>
      <ContentHeader text={`カメラアングル ー ${data?.data.name ?? ''}`} active={activeApplySever} onClick={update} />
      {isLoading ?
        <>loading...</> 
      :  
        <Outlet />
      }
      <ProgressModal />
    </>
  )
}

export default Common;