import { Box, Stack } from "@mui/material"
import { ApplyButton, RedButton, GrayButton } from "components/layouts/button/Base"
import { BaseBoldText } from "components/layouts/text/Base"
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useLocation } from "react-router-dom";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from "react";
import { useSetAtom } from "jotai";
import { progressModalAtom } from "../atoms/common";
import { ModalBase } from "components/layouts/modal/Base";
import { ModalContentText } from "components/layouts/text/Base";
import { useMutation } from "@tanstack/react-query";
import { restartWowza } from "services/auth";

type ContentHeaderProps = {
  text: string,
  active: boolean | null,
  onClick: () => void
}
/**
 * 各画面のタイトルと　Apply Server Button共通
 */
const ContentHeader: React.FC<ContentHeaderProps> = ({text, active, onClick}) => {
  const { pathname } = useLocation()
  // ApplyServerbutoon非表示画面判定
  const isNotDisplay = (pathname.includes('edit') || pathname.includes('create'))

  // 通信モーダル Set Atom
  const setProgressModal = useSetAtom(progressModalAtom)
  // wowza modal
  const [restartModal, setRestartModal] = useState(false)

  // wowza再起動処理
  const { mutate: onRestartWowza } = useMutation(
    restartWowza,
    {
      onSuccess: () => {
        setProgressModal(false)
      }
    }
  )
  // wowza再起動実行 onClick
  const onRestart = () => {
    setRestartModal(false)
    setProgressModal(true)
    onRestartWowza()
  }
  return (
    <>
    <Stack direction="row" justifyContent="space-between" sx={{ pt: 2, pb: 1, borderBottom: "1px solid #edf1f7", mb: 1 }}>
      <BaseBoldText text={text} props={{ my: 1 }}/>
      <Box display="flex">
        <RedButton
          startIcon={<RestartAltIcon />}
          onClick={() => setRestartModal(true)}
        >
          Restart Wowza
        </RedButton>
        {!isNotDisplay &&
          <ApplyButton 
            startIcon={<AutorenewIcon />}
            onClick={onClick}
            disabled={!active}
            sx={{ ml: 1 }}
          >
            Apply Server
          </ApplyButton>
        }
      </Box>
    </Stack>
    {restartModal &&
      <ModalBase
        title="Restart Wowza"
        content={<ModalContentText text="Wowzaを再起動しますか?" />}
        actions={
          <Stack direction="row" justifyContent="space-around" width="100%">
            <GrayButton
              startIcon={<CancelIcon />}
              onClick={() => setRestartModal(false)}
            >
              キャンセル
            </GrayButton>
            <RedButton
              startIcon={<RestartAltIcon />}
              onClick={onRestart}
            >
              再起動
            </RedButton>
          </Stack>
        }
      />
    }
    </>
  )
}

export default ContentHeader;