import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom"
import { useEffect } from "react";
import Login from "../pages/login/Index";
import { AuthenticatedLayout } from "../pages/parts/AuthenticatedLayout";
import Stream from "../pages/streams/Index";
import Cameras from "../pages/cameras/Index";
import { useAtom, useSetAtom } from "jotai";
import { authAtom } from "../pages/atoms/auth";
import RoomEdit from "components/pages/cameras/Edit";
import Common from "components/pages/cameras/parts/Common";
import { useQuery } from "@tanstack/react-query";
import { getStreams } from "services/streams";
import { streamsAtom, streamStatusAtom } from "components/pages/atoms/streams";
import RoomCreate from "components/pages/cameras/Create";
import { activeApplySeverAtom } from "components/pages/atoms/common";

/**
 * ルーティング 
 */
const Router: React.FC = () => {
  const [auth] = useAtom(authAtom)
  return (
    <>
      <ScrollToTop />
      {auth.auth ? 
        <AuthenticatedRouter /> 
      : 
        <UnAuthenticatedRouter />
      }
    </>
  )
}

/**
 * 遷移時、トップへスクロールコンポーネント 
 */
const ScrollToTop = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return <></>
}

/**
 * 認証済みルーティング
 */
const AuthenticatedRouter = () => {
  const setStreams = useSetAtom(streamsAtom)
  const setStreamStatus = useSetAtom(streamStatusAtom)
  const { refetch, status } = useQuery(['getStreams'],
    getStreams,
    {
      onSuccess: (data) => {
        setStreams(data.data)
      }, 
      onError: (error) => {
        throw error
      }
    }
  )
  // ApplySeverButton 有効判定Atom
  const [activeApplySever] = useAtom(activeApplySeverAtom)
  // ApplySeverButton の状態変化で非活性になったら Stream情報再取得
  useEffect(() => {
    if (activeApplySever === false) refetch()
  }, [activeApplySever, refetch])
  // ステータス変わったらSet
  useEffect(() => {
    setStreamStatus(status)
  }, [status, setStreamStatus])
  return (
    <Routes>
      <Route path="manage">
        <Route index element={<Navigate to="manage/streams" />}/>
        <Route path="streams" element={<AuthenticatedLayout/>}>
          <Route index element={<Stream />} />
        </Route>
        <Route path="cameras" element={<AuthenticatedLayout />}>
          <Route index element={<Navigate to="manage/streams" />}/>
          <Route path=":site_id" element={<Common />}>
            <Route index element={<Cameras/>} />
            <Route path="create" element={<RoomCreate />} />
            <Route path="edit" element={<Outlet/>}>
              <Route path=":room_no" element={<RoomEdit />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="manage/streams"/>} /> 
    </Routes>
  )
}

/**
 * 未認証ルーティング
 */
const UnAuthenticatedRouter = () => {
  return (
    <Routes>
      <Route path="auth/login" element={<Login />} />
      <Route path="*" element={<Navigate to="auth/login"/>} />
    </Routes>
  )
}

export default Router;