// バリデーション定義
export const VALIDATION = {
  // auth系
  email: {
    required: '入力してください。',
    pattern: {
      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      message: "正しいメールアドレスではありません。"
    }
  },
  password: {
    required: "入力してください。",
    minLength: {
      value: 4,
      message: "文字数は4文字~50文字で入力してください。",
    },
    maxLength: {
      value: 50,
      message: "文字数は4文字~50文字で入力してください。",
    }
  },
  confirm_pass: {
    required: "入力してください。",
  },
  // Stream系
  name: {
    required: '必須項目です。',
    pattern: {
      value: /^[a-zA-Z0-9_-]+$/,
      message: '英数 ’_’ ’-’ のみ使用可能です。'
    },
  },
  host: {
    required: '必須項目です。',
    pattern: {
      value: /^nc-.+\.(miemasu|iobb)\.net+$/,
      message: '入力形式が違います。'
    }
  },
  rtsp: {
    required: '必須項目です。',
    min: {
      value: 0,
      message: 'ポート番号 0-65535の間で入力してください。' 
    },
    max: {
      value: 65535,
      message: 'ポート番号 0-65535の間で入力してください。'
    }
  },
  http: {
    required: '必須項目です。',
    min: {
      value: 0,
      message: 'ポート番号 0-65535の間で入力してください。' 
    },
    max: {
      value: 65535,
      message: 'ポート番号を入力してください。'
    }
  },
  // Room系
  room_name: {
    required: '入力してください。',
  }
}