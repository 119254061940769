import { axiosClient } from "lib/axios"

// ログイン認証
type TLogin = { email: string, password: string}
export const authLogin = async (params: TLogin) => {
  await axiosClient.get("/sanctum/csrf-cookie")
  const response = await axiosClient.post("/api/login", params)
  return response
}

// ログアウト
export const authLogout = async () => {
  const response = await axiosClient.post("/api/logout")
  return response
}

// パスワード変更
type TChangePassword = { password: string }
export const updatePassword = async (params: TChangePassword) => {
  const response = await axiosClient.patch("/api/password", params)
  return response
}

// Wowza 再起動
export const restartWowza = async () => {
  const response = await axiosClient.post("/api/wowza/restart")
  return response
}