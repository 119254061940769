import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { roomsAtom } from "components/pages/cameras/atoms/rooms";
import { TRoom } from "types/api";
import CreateEdit from "./parts/CreateEdit";

/**
 * Room Create画面
 */
const RoomCreate: React.FC = () => {
  // Rooms Atom情報取得
  const [rooms] = useAtom(roomsAtom)

  // roomsの最後の要素がis_maruchi=1かどうか
  const isMaruchi = rooms.length > 0 ? rooms[rooms.length - 1].is_maruchi : 0

  // pathからsite_id , room_noを取得
  const { site_id } = useParams()
  const room: TRoom = {
    id: undefined,
    site_id: Number(site_id),
    no: isMaruchi ? rooms.length : rooms.length + 1,
    name: '',
    is_maruchi: 0,
    cameras: [],
  }

  return (
    <CreateEdit room={room} type="create" />
  ) 
} 
export default RoomCreate;