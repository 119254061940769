import { Box, Switch } from "@mui/material"
import { GrayButton, GreenButton } from "components/layouts/button/Base"
import { VisibleModal } from "components/layouts/modal/Base"
import { BaseBoldText } from "components/layouts/text/Base"
import { atom, useAtom } from "jotai"
import { Controller, useFormContext } from "react-hook-form"
import Select from "react-select"
import { cameraModalAtom } from "../atoms/common"
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { streamsAtom } from "components/pages/atoms/streams"
import { useEffect } from "react"

const CameraSelectModal = () => {
  // カメラ　モーダル
  const [cameraModal, setCameraModal] = useAtom(cameraModalAtom)
  // ストリームSelect options Atom
  const [options] = useAtom(streamOptionsAtom)
  // OKボタン有効判定　Atom
  const [active, setActive] = useAtom(activeButtonAtom)
  // should_record Atom
  const [record, setRecord] = useAtom(shouldRecordAtom)
  useEffect(() => {
   if (cameraModal?.stream?.should_record === 1) {
    setRecord(true)
   }
  }, [cameraModal?.stream?.should_record, setRecord])
  const { control, reset, setValue } = useFormContext()

  // checked Atom
  const [checked, setChecked] = useAtom(checkedAtom)
  useEffect(() => {
    setChecked(cameraModal?.is_vod_active === 1)
  }, [cameraModal?.is_vod_active, setChecked])

  // キャンセルボタン処理
  const onClose = () => {
    reset()
    setRecord(null)
    setChecked(false)
    setCameraModal(null)
  }
  
  // 新規カメラ追加用・ストリーム選択時処理
  const onSelectStream = (stream_id: number | undefined) => {
    setValue("is_vod_active", false)
    setChecked(false)
    const stream = options.find((x) => x.value === stream_id)
    if (stream) {
      setRecord(stream.should_record === 1)
    }
  }

  return (
    <VisibleModal
      title={`camera ${cameraModal?.no}`}
      content={
        <Box sx={{ mb: 1 }}>
          <BaseBoldText text="Name(Host)" />
          <Controller
            name="stream_id"
            control={control}
            defaultValue={cameraModal?.stream?.id}
            render={({ field }) => (
              <Select
                options={options}
                value={options.find((x) => x.value === field.value)}
                onChange={(newValue) => {
                  const stream_id = newValue?.value
                  onSelectStream(stream_id)
                  field.onChange(stream_id)
                  setActive(true)
                }}
                styles={{
                  menuPortal(base, props) {
                    return { ...base, zIndex: 9999 }
                  },
                  menuList: (base) => ({
                    ...base,
                    zIndex: 9999,
                    maxHeight: "400px"
                  })
                }}
              />
            )}
          />
          {record ? 
            <div>
              <BaseBoldText text="VOD" props={{ mt: 1 }} />
              <Controller
                name="is_vod_active"
                control={control}
                render={({ field }) => (
                  <Switch
                    checked={checked}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.checked)
                      setChecked(e.target.checked)
                      setActive(true)
                    }}
                  />
                )}
              />         
            </div> 
          : 
            <Controller
              name="is_vod_active"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Box sx={{ height: '65.5px' }}>
                  <input type="hidden" {...field} />
                </Box>
              )}
            />
          }
        </Box>
      }
      actions={
        <>
          <GrayButton startIcon={<CancelIcon />} onClick={onClose}>
            キャンセル
          </GrayButton>
          <GreenButton
            startIcon={<CheckIcon />}
            onClick={() => {
              setActive(false)
              setRecord(null)
              cameraModal?.onClick()
            }}
            disabled={!active}
          >
            OK
          </GreenButton>
        </>
      }
    />
  )
}

// OKボタン有効判定Atom
const activeButtonAtom = atom(false)

// camera react-select用 Atom
const streamOptionsAtom = atom(
  (get) => get(streamsAtom).map((value) => (
    {
      value: value.id,
      label: `${value.name}(${value.host})`,
      should_record: value.should_record
    }
  ))
)

// stream should_record Atom
const shouldRecordAtom = atom<boolean | null>(null)

// checked Atom
const checkedAtom = atom<boolean>(false)

export default CameraSelectModal;