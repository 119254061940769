import { atom } from "jotai"
import { atomWithStorage } from "jotai/utils"

export type authState = {
  auth: boolean,
  email: string,
  password: string,
}

export const initialAuthAtom = {
  auth: false,
  email: '',
  password: '',
}

// 永続化　認証State (https://jotai.org/docs/utils/atom-with-storage)
export const authAtom = atomWithStorage('auth', initialAuthAtom)

// 認証401判定
export const unAuthrizedAtom = atom(false)
