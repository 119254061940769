import { FormControl, MenuItem, Select, TextField } from "@mui/material"
import { BaseText } from "components/layouts/text/Base";
import { DEFAUL_TEXTFIELD, NUMBER_TEXTFIELD } from "config/style";

// 入力フォームフィルター
export const InputFilter = ({columnFilterValue, column, table}: any) => {
  const meta = column.columnDef.meta
  return (
    <TextField
      value={(columnFilterValue ?? '') as string}
      onChange={e => {
        column.setFilterValue(e.target.value)
        table.setPageIndex(0)
      }}
      placeholder={column.id}
      type={meta?.input_type}
      inputProps={
        meta?.input_type === 'number' ?
          { ...NUMBER_TEXTFIELD }
        :
          { ...DEFAUL_TEXTFIELD }
      }
    />
  )
}
  
  // セレクトフィルター
export const SelectFilter = ({columnFilterValue, column, table}: any) => {
  const meta = column.columnDef.meta
  return (
    <FormControl variant="standard">
      <Select
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={e => {
          column.setFilterValue(() => [
            e.target.value,
            e.target.value
          ])
          table.setPageIndex(0)
        }}
      >
        <MenuItem value={''}>　</MenuItem>
        {Object.keys(meta?.options).map((value: any, index: number) => 
          <MenuItem key={index} value={value}>
            {meta?.options[value]}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}
  
  // チェックボックスフィルター(ON/OFF系)
export const CheckBoxFilter = ({columnFilterValue, column, table}: any) => {
  return (
    <FormControl variant="standard">
      <Select
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={e => {
          column.setFilterValue(() => [
            e.target.value,
            e.target.value
          ])
          table.setPageIndex(0)
        }}
      >
        <MenuItem value={''}>　</MenuItem>
        <MenuItem value={0}><BaseText text="OFF"/></MenuItem>
        <MenuItem value={1}><BaseText text="ON" /></MenuItem>
      </Select>
    </FormControl>
  )
}