import { atom } from "jotai";
import { TCamera } from "types/api";

const initalCameras: TCamera[] = []
// Room Edit画面
export const camerasAtom = atom(initalCameras)

// RoomのカメラのStream情報を更新
type cameraStreamType = { rowIndex: string, camera: TCamera }
export const updateRoomCameraAtom = atom(
    (get) => get(camerasAtom),
    (get, set, update_data: cameraStreamType) => {
      set(camerasAtom, get(camerasAtom).map((camera, index) => {
        return index === Number(update_data.rowIndex) ?
          update_data.camera
        :
          camera
      }))
    }
)

// Roomにカメラを追加 
export const addRoomCameraAtom = atom(
  (get) => get(camerasAtom),
  (get, set, data: TCamera) => {
    const newValue = get(camerasAtom)
    newValue.push(data)
    set(camerasAtom, newValue.map((camera) => {
      return camera
    }))
  }
)

// Roomのカメラを削除
export const deleteRoomCameraAtom = atom(
  (get) => get(camerasAtom),
  (get, set, rowIndex: string) => {
    set(camerasAtom, get(camerasAtom).filter((_, index) => index !== Number(rowIndex))
      .map((value, index) => {
        value.stream_id = value.stream?.id
      return  {
          ...value,
          no: index + 1,
          is_changed: true
        }
      })
    )
  }
)

// Room編集画面 Saveボタン有効判定
export const roomSaveAtom = atom(false)