import { Alert, AlertTitle, Box, Snackbar } from "@mui/material"
import { alertAtom } from "components/pages/atoms/common"
import { useAtom } from "jotai"
import { BaseBoldText, BaseText } from "../text/Base";

// ログイン失敗アラート
export const LoginAlert = () => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      open={true}
    >
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>  
        メールアドレスまたは、パスワードが正しくありません。
      </Alert>
    </Snackbar>
  )
}

// アラート
export const AlertBase = () => {
  const [alert, setAlert] = useAtom(alertAtom)
  const onClose = () => {
    setAlert(null)
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(alert)}
      autoHideDuration={2000}
      onClose={onClose}
    >
      <Alert severity="error">
        {alert !== null ?
          <>
            <AlertTitle>Error</AlertTitle>
            {Object.keys(alert).map((key, index) => (
              <Box display="flex" key={index}>
                <BaseBoldText text={`${key}：`} />
                <BaseText text={String(alert[key]?.message)} />
              </Box>
            ))}
          </>
        :
          <></>
        }
      </Alert>
    </Snackbar>
  )
}