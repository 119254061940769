import { atom } from "jotai"
import { FieldErrorsImpl } from "react-hook-form";

// Apply Sever Button 有効判定
type TApplyServer = boolean
export const activeApplySeverAtom = atom<TApplyServer>(false)

// 通信中モーダルAtom
export const progressModalAtom = atom(false)

// パスワード変更モーダル Atom
export const passwordModalAtom = atom(false)

// アラート表示 Atom
export type TStatusCodeError = { status: {message: any} }
export type alertState = null | Partial<FieldErrorsImpl>
export const alertAtom = atom<alertState>(null)

// サイト名保持 Atom
export const siteNameAtom = atom<string | null>(null)