import { atom } from "jotai"
import { TStream } from "types/api"
import { streamsAtom } from "components/pages/atoms/streams"


// ストリーム行削除　Atom
export const deleteStreamAtom = atom(
  (get) => get(streamsAtom),
  (get, set, stream: TStream) => {
    set(streamsAtom, get(streamsAtom).filter((item) => { return  (item.name !== stream.name)}))
  }
)

// ストリーム行アップデート　Atom
export type updateStream = { rowIndex: string, value: TStream }
export const updateStreamAtom = atom(
  (get) => get(streamsAtom),
  (get, set, stream_data: updateStream) => {
    set(streamsAtom, get(streamsAtom).map((row, index) => {
      return  (index === Number(stream_data.rowIndex)) ? 
        {
          ...stream_data.value, 
          id: row.id,
          down_times: row.down_times,
          is_assigned: row.is_assigned,
          is_changed: true
        }
      : 
        row
    }))
  }
)

// API送信用　削除ストリーム情報Atom
const initialDeleteStreamsAtom: TStream[] = []
export const deleteStreamsApiAtom = atom(initialDeleteStreamsAtom)