import React from 'react';
import Providers from 'components/providers/Providers';
import Router from './components/routes/Router';

const App: React.FC = () => {
  return (
    <Providers>
      <Router/>
    </Providers>
  )
}

export default App;