import { GrayButton, RedButton } from "components/layouts/button/Base"
import { ModalBase } from "components/layouts/modal/Base"
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { useAtom } from "jotai";
import { deleteModalAtom } from "../atoms/common";
import { ModalContentText } from "components/layouts/text/Base";

const DeleteModal: React.FC = () => {
  const [deleteModal, setDeleteModal] = useAtom(deleteModalAtom)
  return (
    <ModalBase
      title={String(deleteModal?.title)}
      content={<ModalContentText text={String(deleteModal?.contents)} />}
      actions={
        <>
          <GrayButton startIcon={<CancelIcon />} onClick={() => setDeleteModal(null)}>
            キャンセル
          </GrayButton>
          <RedButton startIcon={<CheckIcon />} onClick={deleteModal?.onClick}>
            OK
          </RedButton>
        </>
      }
    />
  )
}

export default DeleteModal;