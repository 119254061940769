import { AppBar, Avatar, Box, Collapse, Container, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Toolbar, Typography } from "@mui/material";
import React, { useCallback } from "react";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyIcon from '@mui/icons-material/Key';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Outlet, useNavigate } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { BaseBoldText } from "components/layouts/text/Base";
import { DrawerBase, DrawerHeader } from "components/layouts/drawer/Base";
import { atom, useAtom } from "jotai";
import { authAtom, initialAuthAtom } from "components/pages/atoms/auth";
import { sitesAtom } from "components/pages/atoms/sites";
import { useMutation, useQuery } from "@tanstack/react-query";
import { authLogout } from "services/auth";
import { AvatarString } from "lib/function";
import { getSites } from "services/sites";
import { passwordModalAtom, siteNameAtom } from "../atoms/common";
import { AlertBase } from "components/layouts/alert/Base";
import ChangePasswordModal from "./ChangePasswordModal";
import { TSite } from "types/api";

/**
 * 認証済共通レイアウト
 */
export const AuthenticatedLayout = () => {
  return (
    <Box display="flex">
      <Header/>
      <Container component="main" maxWidth="xl" sx={{ flexGrow: 1, py: 2 }}>
        <AlertBase />
        <DrawerHeader />
        <Outlet />
      </Container>
    </Box>
  )
}

const Header: React.FC = () => {
  const navigate = useNavigate()
  // 認証情報
  const [auth, setAuth] = useAtom(authAtom)
  
  // パスワード変更 モーダル set Atom
  const [passwordModal, setPasswordModal] = useAtom(passwordModalAtom)

  // ログアウト処理
  const {mutate: logout} = useMutation(
    authLogout,
    {
      onSuccess: () => {
        setAnchorEl(null)
        setAuth(initialAuthAtom)
        navigate("/auth/login")
      },
    }
  )

  // ユーザーメニュー関連
  const [anchorEl, setAnchorEl] = useAtom(userMenuAtom)
  const setAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const closeAnchor = () => {
    setAnchorEl(null)
  }
  // メニュー開閉判定
  const isOpenAccount = Boolean(anchorEl)
  // パスワード変更モーダル開く
  const openPasswordModal = () => {
    setPasswordModal(true)
    closeAnchor()
  }

  return (
    <>
    <AppBar position="fixed" color="inherit" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar disableGutters>
        <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
          <Box display="flex" sx={{ textAlign: "center", my: 'auto', mx: 1}}>
            <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="React.log" height="24px"/>
            <Typography sx={{ mx: 1 }}>T24 Admin</Typography>
          </Box>
          <Box display="flex" aria-controls={isOpenAccount ? "account-menu" : undefined}
            onClick={setAnchor}
            sx={{ textAlign: "center", my: 'auto', cursor: 'pointer' }}
          >
            <Avatar sx={{ width: "24px", height: "24px"}}>{AvatarString(auth.email)}</Avatar>
            <Typography sx={{ mx: 1 }}>{auth.email}</Typography>
          </Box>
        </Stack>
        <Menu
          open={isOpenAccount}
          anchorEl={anchorEl}
          id="account-menu"
          sx={{ mt: "45px" }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={closeAnchor}
        >
          <MenuItem onClick={openPasswordModal}>
            <ListItemIcon>
              <KeyIcon />
            </ListItemIcon>
            <ListItemText primary={<BaseBoldText text="CHANGE PASS"/>} />
          </MenuItem>
          <MenuItem onClick={() => logout()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={<BaseBoldText text="SIGN OUT"/>} />
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
    <SideMenu />
    {passwordModal &&
      <ChangePasswordModal />
    }
    </>
  )
}
// ユーザーmenu開閉判定Atom
const userMenuAtom = atom<HTMLElement | null>(null)

/**
 * サイドメニュー
 */
const SideMenu: React.FC = () => {
  const navigate = useNavigate()
  // サイト情報Atom
  const [sites, setSites] = useAtom(sitesAtom)  
  // サイト名Atom
  const [siteName, setSiteName] = useAtom(siteNameAtom)
  //サイト情報取得                                     
  const { isLoading } = useQuery(['getSites'],  
    getSites,
    {
      onSuccess: (data) => {
        setSites(data.data)
      }
    }  
  )
  // カメラアングル管理　メニュー開閉
  const [openCamera, setOpenCamera] = useAtom(siteListsAtom)
  const onToggleCamera = useCallback(() => {
    setOpenCamera((prevState) => !prevState)
  }, [setOpenCamera])

  // Sidebar 開閉
  const [drawer, setDrawer] = useAtom(sidebarAtom)
  const onToggleDrawer = useCallback(() => {
    setDrawer((prevState) => !prevState)
  }, [setDrawer])
  // Sidebar閉じてる状態でカメラアングル管理Icon押した時
  const openCameraDrawer = () => {
    setDrawer(true)
    setOpenCamera(true)
  }
  // site遷移
  const siteNavigate = (value: TSite) => {
    navigate(`/manage/cameras/${value.id}`)
  }
  // stream遷移
  const streamNavigate = () => {
    setSiteName(null)
    navigate('/manage/streams')
  }
  return (
    <DrawerBase open={drawer} variant="permanent">
      <DrawerHeader sx={{ backgroundColor: 'transparent'}}></DrawerHeader>
      <Divider/>
      <List>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton sx={{ minHeight: 48, px: 2.5, justifyContent: drawer ? 'initial' : 'center'}} onClick={streamNavigate}>
            <ListItemIcon sx={{ minWidth: 0, mr: drawer ? 1 : 'auto', justifyContent: 'center'}}>
              <VideocamIcon color={siteName === null ? 'primary' : 'inherit'}/>
            </ListItemIcon>
            <ListItemText primary={<BaseBoldText text="ストリーム"/>} sx={{ opacity: drawer ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton sx={{ minHeight: 48, px: 2.5, justifyContent: drawer ? 'initial' : 'center'}} onClick={drawer ? onToggleCamera : openCameraDrawer}>
            <ListItemIcon sx={{ minWidth: 0, mr: drawer ? 1 : 'auto', justifyContent: 'center' }}>
              <YouTubeIcon color={siteName !== null ? 'primary': 'inherit'} />
            </ListItemIcon>
            <ListItemText primary={<BaseBoldText text="カメラアングル"/>} sx={{ opacity: drawer ? 1 : 0 }}/>
            {drawer ?
              openCamera ? <ExpandLess /> : <ExpandMoreIcon />
              :
              <></>
            }
          </ListItemButton>
          <Collapse in={openCamera} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {isLoading ? 
                <>loading...</>
              :
              <>
                {sites.map((value: TSite, index: number) => (
                  <ListItemButton key={index} sx={{ pl: 4 }} onClick={() => siteNavigate(value)}>
                    <ListItemText sx={{ opacity: drawer ? 1 : 0  }} 
                      primary={<BaseBoldText text={value?.name} props={{ color: siteName === value.name ? "#1976d2" : 'inherit'}} />}
                    />
                  </ListItemButton>
                ))}
              </>
              }
            </List>
          </Collapse>
        </ListItem>
        <ListItem disablePadding 
          sx={{ display: 'block', position: 'fixed', bottom: 0, maxWidth: drawer ? "218px" : '64px' }}
        >
          <ListItemButton onClick={onToggleDrawer}
            sx={{ minHeight:48, justifyContent: drawer ? 'initial': 'center', px: 2.5 }}
          >
            <ListItemIcon sx={{ minWidth: 0, mr: drawer ? 1 : 'auto', justifyContent: 'center' }}>
              {drawer ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon /> }
            </ListItemIcon>
            <ListItemText primary={<BaseBoldText text="サイドバーを隠す" />} sx={{ opacity: drawer ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
    </DrawerBase>
  )
}

// カメラアングル　サイト一覧開閉判定 Atom
const siteListsAtom = atom(false)
// サイドバー開閉判定　Atom
const sidebarAtom = atom(false)