import { TableCell, TableRow, Checkbox, FormControl, IconButton, MenuItem, Select, TextField } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { Controller, useForm } from "react-hook-form";
import { useAtom, useSetAtom } from "jotai";
import { STREAM_ENCODER, STREAM_PASSWORD, STREAM_TYPE } from "config/column";
import { activeApplySeverAtom, alertAtom } from "components/pages/atoms/common";
import { VALIDATION } from "config/validation";
import { streamsAtom } from "components/pages/atoms/streams";
import { addButtonAtom } from "../atoms/common";
import { NUMBER_TEXTFIELD } from "config/style";

/**
 * 新規データ追加Formコンポーネント
 */
type StreamFrom = {
  id: string,
  name: string,
  host: string,
  rtsp: number,
  http: number,
  type: number,
  is_tcp: number,
  should_record: number,
  passwd_type: number,
  encoder_id: number,
}

// 新規ストリーム追加
export const CreateRow = ({setPage}: any) => {
  // ストリームテーブルAtom
  const [streams, setStreams] = useAtom(streamsAtom)
  
  // ストリーム新規追加Add Button Atom
  const setAddButton = useSetAtom(addButtonAtom)

  // Apply Server botton有効判定セット　Atom
  const setActiveApplySever = useSetAtom(activeApplySeverAtom)

  // フォーム定義
  const {control, register, handleSubmit, reset, formState: {errors} } = useForm<StreamFrom>()
  // アラート Set Atom
  const setAlert = useSetAtom(alertAtom)
  // データ追加
  const onAddData = (value: any) => {
    value.encoder_id = Number(value.encoder_id)
    value.passwd_type = Number(value.passwd_type)
    value.should_record = Number(value.should_record)
    value.is_tcp = Number(value.is_tcp)
    value.type = Number(value.type)
    setStreams(streams.concat([value]))
    setActiveApplySever(true)
    setPage()
    reset()
    setAddButton(false)
  }
  // validationError
  const onError = (errors: any) => setAlert(errors)
  const onUniqueStreamName = (stream_name: string) => {
    const streamFilter = streams.find((value) => value.name === stream_name)
    return !Boolean(streamFilter)
  }
  return (
    <>
    <TableRow>
      <TableCell>
        <Controller
          defaultValue=""
          name="name"
          control={control}
          rules={
            {
              validate: {
              unique: v => onUniqueStreamName(v) || 'ストリーム名が重複しています。',
              },
              ...VALIDATION.name
            }
          }
          render={({ field, fieldState }) => (
            <TextField
              error={Boolean(fieldState.error)}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          defaultValue=""
          name="host"
          control={control}
          rules={{
            ...VALIDATION.host
          }}
          render={({ field }) => (
            <TextField
              error={Boolean(errors.host)}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          defaultValue={0}
          name="http"
          control={control}
          rules={{
            ...VALIDATION.http
          }}
          render={({ field, fieldState }) => (
            <TextField
              type="number"
              error={Boolean(fieldState.error)}
              inputProps={{ ...NUMBER_TEXTFIELD }}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          defaultValue={0}
          name="rtsp"
          control={control}
          rules={{
            ...VALIDATION.rtsp
          }}
          render={({ field, fieldState }) => (
            <TextField
              type="number"
              error={Boolean(fieldState.error)}
              inputProps={{ ...NUMBER_TEXTFIELD }}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          defaultValue={1}
          name="type"
          control={control}
          render={({ field }) => (
            <FormControl variant="standard">
              <Select {...field} >
                {Object.keys(STREAM_TYPE).map((value: any, index) => (
                  <MenuItem key={index} value={value}>{STREAM_TYPE[value]}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          defaultValue={0}
          name="is_tcp"
          control={control}
          render={({ field }) => (
            <Checkbox 
              checked={Boolean(field.value)}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          defaultValue={0}
          name="should_record"
          control={control}
          render={({ field }) => (
            <Checkbox
              checked={Boolean(field.value)}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          defaultValue={1}
          name="passwd_type"
          control={control}
          render={({ field }) => (
            <FormControl variant="standard">
              <Select {...field} >
                {Object.keys(STREAM_PASSWORD).map((value: any, index) => (
                  <MenuItem key={index} value={value}>{STREAM_PASSWORD[value]}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          defaultValue={1}
          name="encoder_id"
          control={control}
          render={({ field }) => (
            <FormControl variant="standard">
              <Select {...field} >
                {Object.keys(STREAM_ENCODER).map((value: any, index) => (
                  <MenuItem key={index} value={value}>{STREAM_ENCODER[value]}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={handleSubmit(onAddData, onError)}>
          <CheckIcon color="primary"/>
        </IconButton>
        <input type="hidden" {...register('id')} value={undefined}/>
        <IconButton onClick={() => setAddButton(false)}>
          <CloseIcon color="error"/>
        </IconButton>
      </TableCell>
    </TableRow>
    </>
  )
}