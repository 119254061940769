export const DEFAUL_TEXTFIELD = {
  style: {
    fontSize: "13px",
    fontWeight: 500,
    padding: "2px 7px"
  }
}

export const NUMBER_TEXTFIELD = {
  min: 0,
  max: 65535,
  style: {
    fontSize: "13px",
    fontWeight: 500,
    padding: "2px 7px"
  }
}