import { useEffect } from "react";
import { Checkbox, FormControl, IconButton, MenuItem, Select, Stack, TextField } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CellContext } from "@tanstack/react-table";
import { useMutation } from "@tanstack/react-query";
import { Controller, FieldErrorsImpl, useFormContext } from "react-hook-form";
import { useAtom, useSetAtom } from "jotai";
import { TStream } from "types/api"
import { getAssigningCameras } from "services/streams";
import { modalAtom } from "components/pages/streams/atoms/common";
import { BaseText } from "components/layouts/text/Base";
import { deleteStreamsApiAtom, deleteStreamAtom, updateStreamAtom} from "components/pages/streams/atoms/stream";
import { activeApplySeverAtom, alertAtom } from "components/pages/atoms/common";
import { streamsAtom } from "components/pages/atoms/streams";
import { DEFAUL_TEXTFIELD, NUMBER_TEXTFIELD } from "config/style";

type PropProps = {
  props: CellContext<TStream, unknown>
}
  
// 編集モードEdit Column
export const SelectedEditColumn: React.FC<PropProps> = ({props}) => {
  const { handleSubmit, reset } = useFormContext<TStream>()
  // ストリームテーブルUpdate Atom
  const onRowUpdate = useSetAtom(updateStreamAtom)
  // Apply Server botton有効判定セット　Atom
  const setActiveApplySever = useSetAtom(activeApplySeverAtom)
  // アラート Set Atom
  const setAlert = useSetAtom(alertAtom)
  // 送信処理
  const onSubmit = (value: TStream) => {
    value.encoder_id = Number(value.encoder_id)
    value.passwd_type = Number(value.passwd_type)
    value.should_record = Number(value.should_record)
    value.is_tcp = Number(value.is_tcp)
    value.type = Number(value.type)
    onRowUpdate({rowIndex: props.row.id, value: value})
    setActiveApplySever(true)
    props.row.toggleSelected()
    const pageIndex = props.table.getState().pagination.pageIndex
    props.table.setPageIndex(pageIndex) 
  }
  // エラー処理
  const onError = (errors: Partial<FieldErrorsImpl>) => {setAlert(errors)}
  return (
    <Stack direction="row">
      <IconButton onClick={handleSubmit(onSubmit, onError)}>
        <CheckIcon color="primary"/>
      </IconButton>
      <IconButton onClick={() => {
        const canSelect = props.row.getCanSelect()
        if (!canSelect) return
          reset()
          props.row.toggleSelected()
      }}>
        <CloseIcon color="error"/>
      </IconButton>
    </Stack>
  )
}
  
// 表示モードEdit Column
export const NotSelectedEditColumn: React.FC<PropProps> = ({props}) => {
  const [streams] = useAtom(streamsAtom)
  // ストリームテーブルRow 削除Atom
  const onRowDelete = useSetAtom(deleteStreamAtom)
  // ストリームテーブル削除情報Atom
  const [deleteStreamApi, setDeleteStream] = useAtom(deleteStreamsApiAtom)
  // Apply Server botton有効判定セット　Atom
  const setActiveApplySever = useSetAtom(activeApplySeverAtom)
  // 削除モーダルAtom 
  const setModalBase = useSetAtom(modalAtom)

  // table function
  const rowFn = props.row

  // stream 情報
  const stream = rowFn.original

  const isDisabled = Boolean(streams[Number(rowFn.id)].is_assigned)

  // ゴミ箱Icon 削除処理
  const onDelete = () => {
    // ストリームテーブルAtom情報を削除
    onRowDelete(stream)
    
    // 削除ストリーム情報追加
    if (stream.id) {
      deleteStreamApi.push(stream)
      setDeleteStream(deleteStreamApi)
    }

    // Apply Sever button 有効化
    setActiveApplySever(true)

    // ページ更新
    const { pageSize, pageIndex } = props.table.getState().pagination
    if (streams.length === pageSize) {
      props.table.setPageSize(pageSize - 1)
    }
    props.table.setPageIndex(streams.length % pageSize === 1 ? pageIndex - 1 : pageIndex)
    
    // 削除モーダル閉じる
    setModalBase(null)
  }

  // カメラ設定状況取得
  const {mutate: checkAssigningCameras} = useMutation(
    getAssigningCameras,
    {
      onSuccess: (data) => {
        // 設定カメラがなければ、削除確認表示
        if (data.data.length === 0) {
          setModalBase({
            onClick: () => onDelete(),
            ...stream
          })
        }
      }
    }
  )
  return (
    <Stack direction="row">
      <IconButton onClick={() => {
        const canSelect = props.row.getCanSelect()
        if (!canSelect) return 
          props.row.toggleSelected()
      }}>
        <EditIcon />
      </IconButton>
      <IconButton 
        disabled={isDisabled}
        onClick={
          stream.id ?
            () => checkAssigningCameras(String(stream.id))
          : 
            () => setModalBase({
              onClick: () => onDelete(),
              ...stream
            })
        }
      >
        <DeleteIcon />
      </IconButton>
    </Stack>
  )
}
  
  
// inputフォーム
export const InputColumn: React.FC<PropProps> = ({props}) => {
    // react-hook-form 定義
    const { control, setValue } = useFormContext()
    // カラム情報
    const column = props.column
    // カラム　meta情報
    const meta = column.columnDef.meta
    // カラム名、カラムvalue1
    const column_name = column.id
    const column_value = props.getValue()

    useEffect(() => {
      setValue(column_name, column_value)
    }, [setValue, column_name, column_value])

    const [streams] = useAtom(streamsAtom)
    const onUniqueStreamName = (stream_name: string) => {
      const streamFilter = streams.find((value) => value.name === stream_name && value.id !== props.row.original.id)
      return !Boolean(streamFilter)
    }
    return (
      <Controller
        defaultValue={props.getValue()}
        control={control}
        name={column.id}
        rules={
          column.id === 'name' ? 
            {
              validate: {
                unique: v => onUniqueStreamName(v) || 'ストリーム名が重複しています。'
              },
              ...meta?.validation
            }
          :
            meta?.validation
          }
        render={({ field, fieldState }) => (
        <TextField
          {...field}
          error={Boolean(fieldState.error)}
          type={meta?.input_type}
          inputProps={
            meta?.input_type === 'number' ?
              { ...NUMBER_TEXTFIELD }
            :
              { ...DEFAUL_TEXTFIELD }
          }
        />
        )} 
      />
    )
  }
  
// selectボックス
export const SelectColumn: React.FC<PropProps> = ({props}) => {
  // react-hook-form定義
  const { control, setValue } = useFormContext()
  // カラム情報
  const column = props.column
  // カラム meta情報
  const meta = column.columnDef.meta
  // カラム名, カラムvalue
  const column_name = column.id
  const column_value = props.getValue()

  useEffect(() => {
    setValue(column_name, column_value)
  }, [setValue, column_name, column_value])
  return (
    <Controller
      defaultValue={props.getValue()}
      name={column.id}
      control={control}
      render={({ field }) => (
        <FormControl variant="standard">
          <Select
            {...field}
          >
            {Object.keys(meta?.options).map((value: any, index:number) => 
              <MenuItem key={index} value={value}>
                <BaseText text={meta?.options[value]} />
              </MenuItem>
            )}
          </Select>
        </FormControl>
      )}
    />
  )
}
  
// checkbox
export const CheckBoxColumn: React.FC<PropProps> = ({props}) => {
  // react-hook-form定義
  const { control, setValue } = useFormContext()
  // カラム情報
  const column = props.column

  // カラム名、カラムvalue
  const column_name = column.id
  const column_value = Boolean(props.getValue())

  useEffect(() => {
    setValue(column_name, column_value)
  }, [setValue, column_name, column_value])
  return (
    <Controller
      defaultValue={Boolean(props.getValue())}
      control={control}
      name={column.id}
      render={({ field }) => (
        <Checkbox
          checked={field.value}
          {...field}
        />
      )}
    />
  )
}