import { TCamera } from "types/api"
import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { flexRender, Row } from "@tanstack/react-table"
import { BaseText } from "components/layouts/text/Base"
import { ColumnDef, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import { CAMERA_EDIT_COLUMN, CAMERA_NORMAL_COLUMN } from "config/column"
import { useMemo } from "react"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAtom, useSetAtom } from "jotai"
import { deleteRoomCameraAtom } from "../atoms/roomCameras"
import { streamsAtom } from "components/pages/atoms/streams"
import { useFormContext } from "react-hook-form"
import { roomSaveAtom, updateRoomCameraAtom } from "../atoms/roomCameras"
import { deleteCameraApiAtom } from "../atoms/rooms"
import { cameraModalAtom, deleteModalAtom } from "../atoms/common"

type TableType = 'normal' | 'edit'

type CameraTableProps = {
  cameras: TCamera[],
  type: TableType
}

export const RoomCamerasTable: React.FC<CameraTableProps> = ({cameras, type}) => {

  // カラム定義
  const columns = useMemo<ColumnDef<TCamera>[]>(
    () => type === 'normal' ? CAMERA_NORMAL_COLUMN : CAMERA_EDIT_COLUMN, [type]
  )
  const table = useReactTable({
    data: cameras,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  })
  // テーブルhead情報
  const head = table.getHeaderGroups()[0].headers

  // テーブルbody情報
  const body = table.getRowModel().rows

  return (
    <>
    <TableContainer>
      <Table stickyHeader aria-label="sticky-table">
        <TableHead>
          <TableRow>
            {head.map((header, index) => (
              <TableCell key={index}>
                {header.id !== 'edit' ?
                  <div>
                    <div 
                      {...{
                        className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}                     
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  </div>
                : 
                  <>
                  </>
                }
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {body.length === 0 ?
            <TableRow>
              <TableCell colSpan={6} align="center">
                <BaseText text="データが見つかりません。" />
              </TableCell>
            </TableRow>
          : type === 'normal' ?
            <>
            {body.map((row, index) => (
              <IndexRows key={row.id} row={row} index={index} />  
            ))}
            </>
          : 
            <>
            {body.map((row, index) => (
              <EditRows key={index} row={row} index={index} />
            ))}
            </>
          }
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
}

type TRow = {
  row: Row<TCamera>,
  index: number,
}

const IndexRows: React.FC<TRow> = ({row, index}) => {
  return (
    <TableRow>
      {row.getVisibleCells().map(cell => {
        return (
          <TableCell key={cell.id} sx={{ backgroundColor: index % 2 === 0 ? '#f5f9ff' : 'inherit' }}>
            {cell.column.id === 'is_vod_active' ?
              cell.getContext().row.original.stream?.should_record ? 
                cell.getContext().getValue() === 1 ? 'ON' : 'OFF'
              : <></>
            :
              <BaseText text={String(cell.getContext().getValue())} />
            }
          </TableCell>
        )
      })}
    </TableRow>
  )
}

const EditRows: React.FC<TRow> = ({row, index}) => {
  // Save button 有効判定セットAtom
  const setRoomSave = useSetAtom(roomSaveAtom)

  // カメラ選択モーダル 
  const setCameraModal = useSetAtom(cameraModalAtom)

  // ストリーム情報
  const [streams] = useAtom(streamsAtom)
  // react-hook-form　定義
  const { getValues, reset } = useFormContext()
  // cameras Atom　update Atom
  const updateCameras = useSetAtom(updateRoomCameraAtom)

  // カメラのストリーム情報変更処理
  const onCameraStreamUpdate = (camera: TCamera, rowIndex: string) => {
    // 変更先Stream情報
    const stream_id = Number(getValues('stream_id'))
    const is_vod_active = getValues('is_vod_active') === true ? 1 : 0
    const stream = {stream: (streams.find((stream) => stream.id === stream_id))}
    const update_camera = ({
      id: camera.id,
      room_id: camera.room_id, 
      no: camera.no,
      stream_id: stream_id,
      is_vod_active: is_vod_active,
      is_changed: true,
      ...stream,
    })
    const update_data = {
      rowIndex: rowIndex,
      camera: update_camera
    }
    updateCameras(update_data)
    setRoomSave(true)
    setCameraModal(null)
    reset()
  }


  // 削除モーダル Set Atom
  const setDeleteModal = useSetAtom(deleteModalAtom)
  // Room Camera削除処理　Atom
  const deleteRoomCamera = useSetAtom(deleteRoomCameraAtom)
  const [deleteCameraApi, setDeleteCameraApi] = useAtom(deleteCameraApiAtom)
  // 削除処理
  const onCameraDelete = (camera: TCamera, rowIndex: string) => {
    deleteRoomCamera(rowIndex)
    deleteCameraApi.push(camera)
    setDeleteCameraApi(deleteCameraApi)
    setRoomSave(true)
    setDeleteModal(null)
  }
  return (
    <TableRow>
      {row.getVisibleCells().map((cell) => {
        return (
          <TableCell key={cell.id} sx={{ backgroundColor: index % 2 === 0 ? '#f5f9ff' : 'inherit' }}>
            {cell.column.id === 'edit' ?
              <Stack direction="row">
                <IconButton 
                  onClick={() => setCameraModal({
                    onClick: () => onCameraStreamUpdate(cell.getContext().row.original, cell.getContext().row.id),
                    ...cell.getContext().row.original
                    })}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => setDeleteModal({
                    onClick: () => onCameraDelete(cell.getContext().row.original, cell.getContext().row.id),
                    title: `Camera${cell.getContext().row.original.no} - ${cell.getContext().row.original.stream?.name}`,
                    contents: 'このカメラを削除しますか？',
                  })}
                >
                  <DeleteIcon/>
                </IconButton>
              </Stack>
            : cell.column.id === 'is_vod_active' ?
              cell.getContext().row.original.stream?.should_record ? 
                cell.getContext().getValue() === 1 ? 'ON' : 'OFF'
              : <></>
            :
              <BaseText text={String(cell.getContext().getValue())} />
            }
          </TableCell>
        )
      })}
    </TableRow>
  )
}