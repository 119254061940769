import { streamState } from "components/pages/atoms/streams";
import { axiosClient } from "lib/axios";
import { TStream } from "types/api";

// ストリーム全件取得
export const getStreams = async () => {
  const response = await axiosClient.get("/api/streams")
  return response
}

// ストリームアップデート処理
type TUpdateStream = { streams: streamState[], deleted_streams: TStream[] }
export const updateStreams = async (params: TUpdateStream) => {
  const response = await axiosClient.put('/api/streams', params)
  return response
}

// カメラへのストリーム設定状況の取得
export const getAssigningCameras = async (stream_id: string) => {
  const response = await axiosClient.get(`/api/streams/${stream_id}/cameras`)
  return response
}