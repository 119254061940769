import { Box, Stack, TextField } from "@mui/material";
import { GrayButton, GreenButton, RedButton } from "components/layouts/button/Base";
import { RoomNameText } from "components/layouts/text/Base";
import { useAtom, useSetAtom } from "jotai";
import { useNavigate, useParams } from "react-router-dom";
import { RoomCamerasTable } from "components/pages/cameras/parts/Table";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useEffect } from "react";
import { addRoomCameraAtom, camerasAtom, roomSaveAtom } from "components/pages/cameras/atoms/roomCameras";
import { deleteCameraApiAtom, deleteRoomApiAtom, deleteRoomAtom, SaveRoomAtom } from "components/pages/cameras/atoms/rooms";
import { FieldErrorsImpl, FormProvider, useForm } from "react-hook-form";
import { cameraModalAtom, deleteModalAtom } from "components/pages/cameras/atoms/common";
import CameraSelectModal from "components/pages/cameras/parts/CameraSelectModal";
import { TCamera, TRoom } from "types/api";
import { streamsAtom } from "components/pages/atoms/streams";
import DeleteModal from "components/pages/cameras/parts/DeleteModal";
import { activeApplySeverAtom, alertAtom } from "components/pages/atoms/common";
import { VALIDATION } from "config/validation";

type Props = {
  room: TRoom,
  type: 'create' | 'edit'
}
type TStreamId = {
  stream_id: number
  is_vod_active: number
}
type TRoomName = {
  room_name: string
}
/**
 * Room
 */
const CreateEdit: React.FC<Props> = ({room, type}) => {
  const navigate = useNavigate()
  
  // pathからsite_id , room_noを取得
  const { site_id } = useParams()
 
  // Cameras Atomを取得
  const [cameras, setCameras] = useAtom(camerasAtom)
  // Roomのカメラ情報をセット 
  const initialSetCameras = useCallback(() => {
    setCameras([...room.cameras])
  }, [setCameras, room.cameras])

  // 現在のRoomのCamerasをセット
  useEffect(() => {
    initialSetCameras()
  }, [initialSetCameras])
  
  // Save button 有効判定 Atom
  const [roomSave, setRoomSave] = useAtom(roomSaveAtom)
  
  const [cameraModal, setCameraModal] = useAtom(cameraModalAtom)
  const [deleteModal, setDeleteModal] = useAtom(deleteModalAtom)
  const setActiveApplyServer = useSetAtom(activeApplySeverAtom)
  const setAlert = useSetAtom(alertAtom)
  
  // Camera削除　Set Api Atom
  const setDeleteCameraApi = useSetAtom(deleteCameraApiAtom)
  // Room Edit Cancel ボタン
  const onCancelEditRoom = () => {
    setRoomSave(false)
    setDeleteCameraApi(prevCameras => 
      prevCameras.filter(camera => camera.room_id !== room.id)
    );
    navigate(`/manage/cameras/${(site_id)}`)
  }
  // react-hook-form 定義
  const method = useForm<TStreamId>()
  const { getValues, reset } = method
  
  const { register, handleSubmit, trigger, formState: {errors} } = useForm<TRoomName>({mode: 'onTouched'})
  // streams Atom
  const [streams] = useAtom(streamsAtom)
  
  // add camera初期
  const new_camera: TCamera = {
    room_id: room.id,
    no: cameras.length + 1,
    stream: undefined,
    is_vod_active: 0,
  }
  // カメラ追加　Set Atom
  const setAddCameraStream = useSetAtom(addRoomCameraAtom)
  // カメラ追加処理
  const onCameraStreamAdd = () => {
    const stream_id = Number(getValues('stream_id'))
    const is_vod_active = Number(getValues('is_vod_active'))
    new_camera.is_vod_active = is_vod_active
    const stream = {stream: (streams.find((stream) => stream.id === stream_id))}
    const add_camera = {stream_id: stream_id, ...new_camera, ...stream}
    setAddCameraStream(add_camera)
    setRoomSave(true)
    setCameraModal(null)
    reset()
  }
  
  // Room削除　Set Atom
  const setDeleteRoom = useSetAtom(deleteRoomAtom)
  const [deleteRoomApi, setDeleteRoomApi] = useAtom(deleteRoomApiAtom)
  const onDeleteRoom = () => {
    setDeleteRoom(String(room.no))
    if (room.id) {
      deleteRoomApi.push(room)
      setDeleteRoomApi(deleteRoomApi)
    }
    setDeleteModal(null)
    navigate(`/manage/cameras/${site_id}`)
    setActiveApplyServer(true)
  }
  
  // Rooms Atom反映処理
  const setSaveRoom = useSetAtom(SaveRoomAtom)
  const onChangeRoomName = () => {
    setRoomSave(true)
    trigger('room_name')
  }
  // Room Save処理
  const onSave = (value: TRoomName) => {
    room.name = value.room_name
    room.cameras = cameras
    room.is_changed = true
    const param = {
      room_no: room.no,
      room_data: room
    }
    setRoomSave(false)
    setSaveRoom(param)
    navigate(`/manage/cameras/${site_id}`)
    setActiveApplyServer(true)
  }
  const onError = (errors: Partial<FieldErrorsImpl>) => {
    setAlert(errors)
  }
  return (
    <>
      <Box sx={{ my: 2, px: 2, py: 3, border: "1px solid #edf1f7" }}>
        <Stack direction="row" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {room.is_maruchi ? <>
              <RoomNameText text={room.name} />
              <input type="hidden" {...register('room_name')} defaultValue={room.name} />
            </> : <>
              <RoomNameText text={`Room${room.no} -`} props={{ width:"70%" }}/>
              <TextField defaultValue={room.name}
                size="medium"
                inputProps={{
                  style: {
                    fontSize: "15px",
                    fontWeight: 600,
                    padding: "5px 9px",
                }}}
                error={Boolean(errors.room_name)}
                {...register('room_name', {
                  ...VALIDATION.room_name,
                  onChange: () => onChangeRoomName()
                })} 
              />
            </>}
          </Box>
          <GreenButton startIcon={<AddIcon />}
            sx={{ marginY: 'auto'}}
            onClick={() => setCameraModal({
              onClick: () => onCameraStreamAdd(),
              ...new_camera
            })}
          >
            ADD CAMERA
          </GreenButton>
        </Stack>
        <FormProvider {...method}>
          <RoomCamerasTable cameras={cameras} type='edit' />
          <Stack direction="row" justifyContent="end" sx={{ mt: 3 }}>
            {type === 'edit' &&
              <RedButton startIcon={<DeleteIcon/>}
                onClick={() => setDeleteModal({
                  onClick: () => onDeleteRoom(),
                  title: `Room${room.no} - ${room.name}`,
                  contents: 'このRoomを削除しますか？'
                })}
                disabled={room.is_maruchi === 1}
              >
                DELETE ROOM
              </RedButton>
            }
            <GrayButton startIcon={<CloseIcon />} sx={{ ml: 10 }}
              onClick={onCancelEditRoom}
            >
              CANCEL
            </GrayButton>
            <GreenButton startIcon={<CheckIcon />} sx={{ ml: 1 }} disabled={!roomSave || cameras.length === 0 || Boolean(errors.room_name)}
              onClick={handleSubmit(onSave, onError)}
            >
              SAVE
            </GreenButton>
          </Stack>
          {cameraModal &&
            <CameraSelectModal />
          }
          {deleteModal &&
            <DeleteModal />
          }
        </FormProvider>
      </Box>
    </>   
  )
}
  
export default CreateEdit;

