import { atom } from "jotai";
import { TCamera, TRoom } from "types/api";

// あるサイトのRooms　一覧 ApplyServer絡み
const initialRooms: TRoom[] = []
export const roomsAtom = atom(initialRooms)

// Room削除
export const deleteRoomAtom = atom(
  (get) => get(roomsAtom),
  (get, set, room_no: string) => {
    set(roomsAtom, get(roomsAtom).filter((value) => value.no !== Number(room_no))
      .map((room, index) => (
        room.no !== 0 ?
          {
            ...room,
            no: index + 1,
            is_changed: true
          }
        :
          room
      ))
    )
  }
)

// Room情報をRooms Atomに反映.
type SaveRoomType = { room_no: number, room_data: TRoom }
export const SaveRoomAtom = atom(
  (get) => get(roomsAtom),
  (get, set, data: SaveRoomType) => {
    const rooms = get(roomsAtom)
    
    // roomsの最後の要素がis_maruchi=1かどうか
    const isMaruchi = rooms.length > 0 ? rooms[rooms.length - 1].is_maruchi : 0
    // roomsの長さ取得
    const roomsLength = isMaruchi ? rooms.length : rooms.length + 1
    
    // createの時
    if (roomsLength === data.room_no ) {
      const maruchi = rooms[rooms.length - 1]
      rooms[roomsLength - 1] = data.room_data
      if (isMaruchi) rooms.push(maruchi)
      set(roomsAtom, rooms.map((room) => {
        return room
      }))
    // editの時
    } else {
      set(roomsAtom, get(roomsAtom).map((value, index) => (
        value.no === data.room_no ?
          data.room_data
        :
          value
      )))
    }
  }
)

// API送信用　DeleteCamera情報
const initialDeleteCamera: TCamera[] = []
export const deleteCameraApiAtom = atom(initialDeleteCamera)

// API送信用 DeleteRoom情報
const initialDeleteRoom: TRoom[] = []
export const deleteRoomApiAtom = atom(initialDeleteRoom)