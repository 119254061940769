import { axiosClient } from "lib/axios";
import { TCamera, TRoom } from "types/api";

// サイト全件取得
export const getSites = async () => {
  const response = await axiosClient.get('/api/sites')
  return response
}

// サイトに紐ずく全RoomとCameraを取得
export const getSiteCameras = async (site_id: string) => {
  const response = await axiosClient.get(`/api/sites/${site_id}/cameras`)
  return response
}

// サイトに紐ずく全RoomとCamera情報を更新
type TUpdateSiteCameras = { rooms: TRoom[], deleted_cameras: TCamera[], deleted_rooms: TRoom[] }
export const updateSiteCameras = async (site_id: string, params: TUpdateSiteCameras) => {
  const response = await axiosClient.put(`/api/sites/${site_id}/cameras`, params)
  return response
}