import { createTheme, ThemeProvider  } from '@mui/material';
import { QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { authAtom, initialAuthAtom } from 'components/pages/atoms/auth';
import { alertAtom, progressModalAtom, TStatusCodeError } from 'components/pages/atoms/common';
import { atom, useAtom, useSetAtom } from 'jotai';
import { axiosClient } from 'lib/axios';
import React, { useCallback, useEffect } from 'react';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { authLogout } from 'services/auth';

const Providers = ({children}: {children: React.ReactElement}) => {
  return (
    <ReactQueryProvider>
      <MuiThemeProvider>
        <BrowserRouter>
          <AxiosClientProvider>
            {children}
          </AxiosClientProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    </ReactQueryProvider>  
  )
}

// ReactQuery 設定
const ReactQueryProvider = ({children}: {children: React.ReactElement}) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        staleTime: 90000,
        cacheTime: 0
      },
    }
  })
  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  )
}

// Mui テーマ設定
const MuiThemeProvider = ({children}: {children: React.ReactElement}) => {
  const theme = createTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          size: "small",
          fullWidth: true,
          inputProps: {
            style: {
              fontSize: "13px",
              fontWeight: 500,
              padding: "2px 7px",
            }
          },
        }
      },
      MuiMenuItem: {
        defaultProps: {
          dense: true,
          style: {
            fontSize: "13px",
            fontWeight: 500,
          }
        }
      },
      MuiButton: {
        defaultProps: {
          size: "small",
          variant: "outlined",
          style: {
            fontSize: "0.875rem",
            fontWeight: 700,
            transitionDuration: ".15s",
            transitionProperty: "background-color,border-color,box-shadow,color",
            transitionTimingFunction: "ease-in",
          }
        },
      },
      MuiSelect: {
        defaultProps: {
          size: "small",
          variant: "outlined",
          style: {
            fontSize: "13px"
          },
          inputProps: {
            style: { 
              textAlign: 'center',
            }
          },
        },
        styleOverrides: {
          select: {
            padding: "2px 7px", 
          }
        }
      },
      MuiFormControl: {
        defaultProps: {
          fullWidth: true
        }
      },
      MuiCard: {
        defaultProps: {
          style: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "15px",
            boxShadow: "24px",
            padding: "8px 16px"
          }
        }
      },
    }
  })
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}


// Axios Provider 401 ログアウト処理するインターセプター
const AxiosClientProvider = ({children}: {children: React.ReactElement}) => {
  const navigate = useNavigate()
  const setAuth = useSetAtom(authAtom)
  const setProgressModal = useSetAtom(progressModalAtom)
  const setAlert = useSetAtom(alertAtom)

  // 401以外のエラー処理
  const errorHandler = useCallback((error: AxiosError) => {
    const params: TStatusCodeError = {
      status: {
        message: `${error.response?.status}：${error.response?.statusText}`
      }
    }
    setProgressModal(false)
    setAlert(params)
  }, [setProgressModal, setAlert])
  // logout処理
  const { mutate: logout } = useMutation(
    authLogout,
    {
      onSuccess: () => {
        navigate('/auth/login')
        setAuth(initialAuthAtom)
      },
    }
  )
  // レンダー判定Atom
  const [isRender, setIsRender] = useAtom(renderAtom)
  useEffect(() => {
    // レスポンスのaxios インターセプター
    const responseInterceptor =  axiosClient.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        switch (error.response?.status) {
          case 401:
            logout()
            break
          case 419:
            setProgressModal(false)
            logout()
            break
          default:
            errorHandler(error)
            break
        }
        return Promise.reject(error)
      }
    )
    // children render trueに
    setIsRender(true)
    // レスポンスをeject
    return () => {
      axiosClient.interceptors.response.eject(responseInterceptor)
    }
  }, [setIsRender, logout, errorHandler, setProgressModal])
  return isRender ? <>{children}</> : <></>
}

// render情報　Atom
const renderAtom = atom(false)

export default Providers;