import { atom } from "jotai"
import { TStream } from "types/api"

export type streamState = {
  is_changed: boolean
} & TStream
// ストリームテーブル　初期
export const initialStreamsAtom: streamState[] = []
// ストリーム情報Atom
export const streamsAtom = atom(initialStreamsAtom)

// ストリーム取得ステータスAtom
type streamStatusState = 'loading' | 'error' | 'success'
export const streamStatusAtom = atom<streamStatusState>('loading')