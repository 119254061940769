import { useAtom } from "jotai";
import { Navigate, useParams } from "react-router-dom";
import { roomsAtom } from "components/pages/cameras/atoms/rooms";
import CreateEdit from "./parts/CreateEdit";

/**
 * Room Edit画面
 */
const RoomEdit: React.FC = () => {
  // Rooms Atom情報取得
  const [rooms] = useAtom(roomsAtom)
 
  // pathからsite_id , room_noを取得
  const { site_id, room_no } = useParams()
  const roomFilter = rooms.filter((value) => value.no === Number(room_no))
  const room = roomFilter[0]

  return (
    <>
    {room ?
        <CreateEdit room={room} type="edit" />
      :
        <Navigate to={`/manage/cameras/${site_id}`} />
    }
    </>
  ) 
} 
export default RoomEdit;