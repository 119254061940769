import { ColumnDef } from "@tanstack/react-table"
import { TCamera, TStream } from "types/api"
import { VALIDATION } from "./validation"

interface ISelect {
  [key: number]: string
}

export const STREAM_TYPE: ISelect = {
  1: 'Panasonic',
  2: 'IO(Nx2xx)' ,
  3: 'IO(Other)' ,
}

export const STREAM_PASSWORD: ISelect = {
  1: 'Old',
  2: 'New',
}

export const STREAM_ENCODER: ISelect = {
  1: 't24-enc',
  2: 't24-enc-2',
}

// ストリーム画面　カラム
export const STREAMS_COLUMN: ColumnDef<TStream>[] = [
  {
    id: 'name',
    header: 'Name',
    accessorKey: 'name',
    meta: {
      type: 'input',
      input_type: 'text',
      validation: VALIDATION.name,
      width: 234,
    }
  },
  {
    id: 'host',
    header: 'Host',
    accessorKey: 'host',
    meta: {
      type: 'input',
      input_type: 'text',
      validation: VALIDATION.host,
      width: 139,
    }
  },
  {
    id: 'http',
    header: 'HTTP',
    accessorKey: 'http',
    meta: {
      type: 'input',
      input_type: 'number',
      validation: VALIDATION.http,
      width: 100,
    },
    accessorFn: (row) => String(row.http)
  },
  {
    id: 'rtsp',
    header: 'RTSP',
    accessorKey: 'rtsp',
    meta: {
      type: 'input',
      input_type: 'number',
      validation: VALIDATION.rtsp,
      width: 100,
    },
    accessorFn: (row) => String(row.rtsp)
  },
  {
    id: 'type',
    header: 'Type',
    accessorKey: 'type',
    meta: {
      type: 'select',
      options: STREAM_TYPE,
      substring: true,
      width: 100,
    },
  },
  {
    id: 'is_tcp',
    header: 'TCP',
    accessorKey: 'is_tcp',
    meta: {
      type: 'checkbox',
      width: 66,
    }
  },
  {
    id: 'should_record',
    header: 'Record',
    accessorKey: 'should_record',
    meta: {
      type: 'checkbox',
      width: 66,
    }
  },
  {
    id: 'passwd_type',
    header: 'Password',
    accessorKey: 'passwd_type',
    meta: {
      type: 'select',
      options: STREAM_PASSWORD,
      width: 66,
    }
  },
  {
    id: 'encoder_id',
    header: 'Encoder',
    accessorKey: 'encoder_id',
    meta: {
      type: 'select',
      options: STREAM_ENCODER,
      width: 95.5
    }
  },
  {
    id: 'edit',
    meta: {
      type: 'edit',
      width: 66,
    }
  }
]

export const CAMERA_NORMAL_COLUMN: ColumnDef<TCamera>[] = [
  {
    id: 'no',
    header: 'Camera No',
    accessorKey: 'no',
  },
  {
    id: 'stream.name',
    header: 'Name(Host)',
    accessorKey: 'stream.name',
    accessorFn: (row) => `${row.stream?.name}(${row.stream?.host})`
  },
  {
    id: 'stream.http',
    header: 'HTTP',
    accessorKey: 'stream.http',
  },
  {
    id: 'stream.rtsp',
    header: 'RTSP',
    accessorKey: 'stream.rtsp'
  },
  {
    id: 'is_vod_active',
    header: 'VOD',
    accessorKey: 'is_vod_active',
    accessorFn: (row) => Number(row.is_vod_active)
  } 
]

export const CAMERA_EDIT_COLUMN: ColumnDef<TCamera>[] = [
  {
    id: 'no',
    header: 'Camera No',
    accessorKey: 'no',
  },
  {
    id: 'stream.name',
    header: 'Name(Host)',
    accessorKey: 'stream.name',
    accessorFn: (row) => `${row.stream?.name}(${row.stream?.host})`
  },
  {
    id: 'stream.http',
    header: 'HTTP',
    accessorKey: 'stream.http',
  },
  {
    id: 'stream.rtsp',
    header: 'RTSP',
    accessorKey: 'stream.rtsp'
  },
  {
    id: 'is_vod_active',
    header: 'VOD',
    accessorKey: 'is_vod_active',
    accessorFn: (row) => Number(row.is_vod_active)
  },
  {
    id: 'edit'
  }
]

// テーブルカラム　
export const Column = {
  CAMERAS: {
    // カメラアングル画面 ROOM一覧
    INDEX: [
      {
        id: 'camera_no',
        header: 'Camera No',
        accessorKey: 'camera_no',
      },
      {
        id: 'name',
        header: 'Name(Host)',
        accessorKey: 'name',
      },
      {
        id: 'host',
        header: 'Host:Port',
        accessorKey: 'host',
      }
    ],
    // カメラアングル画面　追加・編集
    EDIT: [
      {
        id: 'camera_no',
        header: 'Camera No',
        accessorKey: 'camera_no',
      },
      {
        id: 'name',
        header: 'Name(Host)',
        accessorKey: 'name',
      },
      {
        id: 'host',
        header: 'Host:Port',
        accessorKey: 'host',
      },
      {
        id: 'edit',
      }
    ]
  }
}
