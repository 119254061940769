import { Typography } from "@mui/material"

type TextType = {
  text: string,
  props?: object,
}

// loginフォームのlabel
export const InputLabelText: React.FC<TextType> = ({text, props}) => {
  return (
    <Typography
      color="#8f9bb3"
      fontSize=".75rem"
      fontWeight={700}
      sx={{ ...props }}
    >
      {text}
    </Typography>
  )
}

// 基本の文字
export const BaseText: React.FC<TextType> = ({text, props}) => {
  return (
    <Typography
      fontSize="13px"
      fontWeight={500}
      sx={{ ...props }}
    >
      {text}
    </Typography>
  )
}

// メニューや画面タイトルの太文字
export const BaseBoldText: React.FC<TextType> = ({text, props}) => {
  return (
    <Typography
      fontSize="13px"
      fontWeight={600}
      sx={{ ...props }}
    >
      {text}
    </Typography>
  )
}

// Room用のテキスト
export const RoomNameText : React.FC<TextType> = ({text, props}) => {
  return (
    <Typography
      fontSize="1.4rem"
      fontWeight={700}
      sx={{ ...props }}
    >
      {text}
    </Typography>
  )
}

// Modal Title用テキスト
export const ModalTitleText: React.FC<TextType> = ({text, props}) => {
  return (
    <Typography
      fontSize="15px"
      fontWeight={600}
      sx={{ ...props }}
    >
      {text}
    </Typography>
  )
}

// Modal Content用テキスト
export const ModalContentText: React.FC<TextType> = ({text, props}) => {
  return (
    <Typography
      fontSize="15px"
      sx={{ ...props }}
    >
      {text}
    </Typography>
  )
}