import { useMutation } from "@tanstack/react-query";
import { streamsAtom } from "components/pages/atoms/streams";
import ContentHeader from "components/pages/parts/ContentsHeader";
import { progressModalAtom } from "components/pages/atoms/common";
import { ProgressModal } from "components/layouts/modal/Base";
import { StreamsTable } from "components/pages/streams/parts/Table";
import { useAtom, useSetAtom } from "jotai";
import { updateStreams } from "services/streams";
import { deleteStreamsApiAtom } from "./atoms/stream";
import { activeApplySeverAtom } from "components/pages/atoms/common";
import { useEffect, useCallback } from "react";

/**
 * ストリーム管理 
 */
const Stream: React.FC = () => {
  // Apply Server button の有効判定 Atom
  const [activeApplyServer, setActiveApplyServer] = useAtom(activeApplySeverAtom)
  // ストリーム情報Atom
  const [streams, setStreams] = useAtom(streamsAtom)
  // ストリーム削除情報 Atom
  const [deleteStreamsApi] = useAtom(deleteStreamsApiAtom)
  // Progress Modal セットAtom
  const setProgress = useSetAtom(progressModalAtom)
  // query params
  const params = {
    streams: streams,
    deleted_streams: deleteStreamsApi
  }
  // stream_update useMutation
  const {mutate: updateStream} = useMutation(
    updateStreams,
    {
      onSuccess: (data) => {
        setActiveApplyServer(false)
        setStreams(data.data)
        setProgress(false)
      },
    }
  )
  // update 処理
  const update = () => {
    setProgress(true)
    updateStream(params)
  }
  
  const disabledApplyServer = useCallback(() => {
    setActiveApplyServer(false)
  }, [setActiveApplyServer])

  useEffect(() => {
    disabledApplyServer()
  }, [disabledApplyServer])
  return (
    <>
      <ContentHeader text="ストリーム" active={activeApplyServer} onClick={() => update()}/>
      <StreamsTable />
      <ProgressModal />
    </>
  )
}

export default Stream;