import { Box, Card, CardActions, CardContent, CardHeader, CircularProgress, Modal, Stack } from "@mui/material"
import { useAtom } from "jotai"
import { ReactNode } from "react"
import { ModalTitleText } from "../text/Base"
import { progressModalAtom } from "../../pages/atoms/common"

type ModalProps = {
  title: string,
  content: ReactNode | string,
  actions: ReactNode,
}

// モーダル
export const ModalBase: React.FC<ModalProps> = ({ title, content, actions}) => {
  return (
    <Modal open={true}>
      <Card sx={{ width: '300px' }}>
        <CardHeader title={<ModalTitleText text={title}/>} sx={{ fontSize: "13px" }}/>
        <CardContent>
          {content}
        </CardContent>
        <CardActions>
          <Stack direction="row" justifyContent="space-around" sx={{ width: '100%' }}>
            {actions}
          </Stack>
        </CardActions>
      </Card>
    </Modal>
  )
}

export const VisibleModal: React.FC<ModalProps> = ({ title, content, actions}) => {
  return (
    <Modal open={true}>
      <Card sx={{ overflow: 'visible', zIndex: 3000, width: '430px' }}>
        <CardHeader title={<ModalTitleText text={title}/>} sx={{ fontSize: "13px" }}/>
        <CardContent>
          {content}
        </CardContent>
        <CardActions>
          <Stack direction="row" justifyContent="space-around" sx={{ width: '100%' }}>
            {actions}
          </Stack>
        </CardActions>
      </Card>
    </Modal> 
  )
}

// Api通信中 ProgressModal
export const ProgressModal: React.FC = () => {
  const [progress] = useAtom(progressModalAtom)
  return (
    <Modal open={progress}>
      <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <CircularProgress
          size={250}
        />
      </Box>
    </Modal>
  )
}